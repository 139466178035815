import { Component, Vue } from 'vue-property-decorator';
import DataTable, { TableActions, TableOptions, TableVisibility } from '@/components/data-table/DataTable';
import CreateProjectDialog from '@/views/Projects/CreateProjectDialog.vue';
import { Project as ProjectModel } from '@/models/Project';
import { PermissionSlug } from '@/support/PermissionSlug';

@Component<Projects>({
  components: {
    CreateProjectDialog,
  },
})
export default class Projects extends Vue {
  public $pageTitle = 'Projecten';

  protected isCreatingProject = false;

  protected visibility: TableVisibility = {
    title: false,
    limit: false,
    checkboxes: false,
  };

  protected mounted() {
    this.initBreadcrumb();
  }

  protected get tableOptions(): TableOptions {
    return {
      model: new ProjectModel(),
      headers: [
        {
          text: 'Naam',
          value: 'name',
          action: '/projecten/{id}',
          sortable: {
            key: 'name',
            order: 'ASC',
          },
          visible: () => this.canReadProjects,
        },
      ],
      actions: this.tableActions,
    };
  }

  protected get tableActions(): TableActions[] {
    return [
      {
        type: 'view',
        label: 'view',
        icon: 'remove_red_eye',
        action: '/projecten/{id}',
        tooltip: 'Project kaart',
        visible: () => this.canReadProjects,
      },
      {
        type: 'view',
        label: 'view',
        icon: 'settings',
        action: '/project-manager/{id}',
        tooltip: 'Project beheren',
        visible: () => this.canUpdateProjects,
      },
    ];
  }

  protected initBreadcrumb() {
    this.$root.$emit('breadcrumbUpdated',
      {
        crumb: [
          { name: 'Alle projecten', path: '/projecten' },
        ],
      });
  }

  protected refreshDataTable() {
    document.dispatchEvent(
      new CustomEvent('datatable:hook', {
        detail: (dataTable: DataTable) => {
          dataTable.refresh();
        },
      }),
    );
  }

  protected get canReadProjects(): boolean {
    return this.$store.state.Auth?.can(PermissionSlug.PROJECTS_READ);
  }

  protected get canUpdateProjects(): boolean {
    return this.$store.state.Auth?.can(PermissionSlug.PROJECTS_UPDATE);
  }
}
