import { Component, Vue, Prop } from 'vue-property-decorator';
import { Project } from '@/models/Project';

@Component<CreateProjectDialog>({})
export default class CreateProjectDialog extends Vue {
  @Prop({ default: false })
  protected value!: boolean;

  protected isLoading = false;

  protected project: Project = new Project();

  protected close() {
    this.$emit('input', false);
  }

  protected create() {
    this.isLoading = true;
    new Project()
      .create({
        type: 'job',
        name: this.project.name,
      }).then(() => {
        this.close();
        this.$emit('created');
        this.isLoading = false;
      });
  }

  protected get isDisabled() {
    return ! this.project.name;
  }
}
