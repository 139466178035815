var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-layout",
        { attrs: { wrap: "" } },
        [
          _c("v-flex", { attrs: { xs6: "", "py-0": "" } }, [
            _c("h1", { staticClass: "elementSubTitle" }, [_vm._v("Projecten")])
          ]),
          _vm.$store.state.isServiceOrganization
            ? _c("v-flex", { staticClass: "relative", attrs: { xs6: "" } }, [
                _c(
                  "div",
                  { staticClass: "elementTitleButton absolute" },
                  [
                    _c(
                      "MiButton",
                      {
                        attrs: {
                          color: "secondary",
                          icon: "add_circle",
                          round: "true",
                          outline: "true",
                          background: "true"
                        },
                        nativeOn: {
                          click: function($event) {
                            _vm.isCreatingProject = true
                          }
                        }
                      },
                      [_vm._v(" Project aanmaken ")]
                    )
                  ],
                  1
                )
              ])
            : _vm._e()
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "elementPanel elementPanel--spaced" },
        [
          _c("DataTable", {
            ref: "projectsDataTable",
            attrs: { options: _vm.tableOptions, visibility: _vm.visibility },
            on: { "data-update": _vm.refreshDataTable }
          })
        ],
        1
      ),
      _c("CreateProjectDialog", {
        on: { created: _vm.refreshDataTable },
        model: {
          value: _vm.isCreatingProject,
          callback: function($$v) {
            _vm.isCreatingProject = $$v
          },
          expression: "isCreatingProject"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }