var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.value
        ? _c(
            "v-dialog",
            {
              attrs: { "max-width": "500", persistent: true },
              model: {
                value: _vm.value,
                callback: function($$v) {
                  _vm.value = $$v
                },
                expression: "value"
              }
            },
            [
              _c(
                "DefaultDialog",
                { on: { close: _vm.close } },
                [
                  _c("template", { slot: "header" }, [
                    _vm._v("Project aanmaken")
                  ]),
                  _vm.isLoading
                    ? _c("LoaderCard", {
                        attrs: { flat: "", type: "spinner--center" }
                      })
                    : _vm._e(),
                  !_vm.isLoading
                    ? [
                        _c(
                          "template",
                          { slot: "content" },
                          [
                            _c(
                              "v-layout",
                              { attrs: { wrap: "" } },
                              [
                                _c(
                                  "v-flex",
                                  { attrs: { xs12: "", "mb-3": "" } },
                                  [
                                    _c("v-text-field", {
                                      attrs: {
                                        label: "Naam",
                                        "hide-details": true
                                      },
                                      model: {
                                        value: _vm.project.name,
                                        callback: function($$v) {
                                          _vm.$set(_vm.project, "name", $$v)
                                        },
                                        expression: "project.name"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "template",
                          { slot: "actions" },
                          [
                            _c(
                              "v-layout",
                              {
                                attrs: { "justify-end": "", "align-center": "" }
                              },
                              [
                                _c(
                                  "v-flex",
                                  { attrs: { shrink: "" } },
                                  [
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticClass: "fadedText link mr-3",
                                          on: { click: _vm.close }
                                        },
                                        [_vm._v("Annuleren")]
                                      ),
                                      _c(
                                        "MiButton",
                                        {
                                          attrs: {
                                            disabled: _vm.isDisabled,
                                            color: "primary",
                                            small: "true"
                                          },
                                          nativeOn: {
                                            click: function($event) {
                                              return _vm.create($event)
                                            }
                                          }
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(_vm.$t("actions.save")) +
                                              " "
                                          )
                                        ]
                                      )
                                    ]
                                  ],
                                  2
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ]
                    : _vm._e()
                ],
                2
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }